import { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Typography } from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import QRCode from "qrcode.react";
import CircularProgress from "@mui/material/CircularProgress";

const fixedAddress = "bc1qhcjdyl2flfvxggnqsy6myvp20d2wfx3gxcqaxj"; // Fixed Bitcoin address


const Spinner = () => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
      <CircularProgress style={{ color: "#2c3691" }} />
    </div>
);

const Payment = ({ user, checkoutData, handleBackStep }) => {
  const [btcAddress, setBtcAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [priceInBTC, setPriceInBTC] = useState(0);

  const p_name = checkoutData.live.line_items.map((item) => {
    return item.name;
  });

  useEffect(() => {
    const fetchBtcAddress = async () => {
      const formData = new FormData();
      formData.append("email", user.email);
      formData.append("price", checkoutData.live.subtotal.formatted);
      formData.append("name", user.firstName);
      formData.append("description", p_name.join(", "));

      try {
        const response = await fetch("https://paybutton.onrender.com/payment", {
          method: "POST",
          body: formData,
        });
        const data = await response.json();

        if (data.address) {
          setBtcAddress(data.address);
        } else {
          setBtcAddress(fixedAddress); // Use the fixed address if API call doesn't return address
        }

        setPriceInBTC(data.priceInBTC);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setBtcAddress(fixedAddress); // Use the fixed address if there's an error
        setIsLoading(false);
      }
    };

    fetchBtcAddress().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const p_email = user.email;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(btcAddress);
  };
  return (
      <>
        <Typography gutterBottom align="center">
          Pay {checkoutData.live.subtotal.formatted_with_code} With Bitcoin
        </Typography>

        {isLoading ? (
            <>
              <Typography variant="body1" align="center">
                Please wait as we generate a Bitcoin address for {p_email}...
              </Typography>
              <Spinner />
            </>
        ) : (
            <>
              <QRCode
                  value={`bitcoin:${btcAddress}?amount=${priceInBTC}`}
                  size={240}
                  fgColor="#424242"
                  bgColor="#ffffff"
                  level="Q"
                  style={{
                    margin: "auto",
                    display: "block",
                    border: "8px solid #ffffff",
                    borderRadius: "10px",
                    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.15)",
                  }}
              />

              <div className="products-spinner" style={{ margin: "auto" }}>
                <SyntaxHighlighter language="javascript" style={dark}>
                  {btcAddress}
                </SyntaxHighlighter>
              </div>

              <div className="products-spinner">
                <CopyToClipboard text={btcAddress} onCopy={handleCopyToClipboard}>
                  <Button size="medium" type="button" variant="outlined" color="primary">
                    Copy to clipboard
                  </Button>
                </CopyToClipboard>
              </div>

              <div
                  style={{
                    color: "#2C3691",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
              >
                After payment: {p_name.join(", ")} will be sent to: {p_email}
              </div>

              <div className="actions payment-actions">
                <Button variant="outlined" onClick={(e) => handleBackStep(e, "order-details")}>
                  Back
                </Button>
              </div>
            </>
        )}
      </>
  );
};

export default Payment;
