import { Container, Typography, Button, Grid } from "@material-ui/core";
import logo from "./logsshop.png";
import "./style.css";

const Banner = () => {
  return (
    <div className="banner">
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography
              className="title"
              variant="h1"
              style={{ color: "green" }}
            >
              100% Working logs.
              We also do Refunds
            </Typography>
            <Typography
              className="title2"
              variant="h1"
              style={{ color: "skyblue" }}
            >
              <a
                href="https://t.me/perigrine/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "skyblue" }}
              >
                Feel free to talk to us if you have any questions
              </a>
              <br />

            </Typography>
            <Typography
              className="title3"
              variant="h1"
              style={{ color: "green" }}
            >
              We accept bitcoin only
            </Typography>
            {/* <div className="channel">
            <Button
            href="https://t.me/gamingthesystemz/"
            target="_blank"
            rel="noopener noreferrer"
             className="channel-button">
              Join Our Channel
            </Button>
            </div> */}
            <Button className="shopping-button" href="#products">
              Shop Now
            </Button>
          </Grid>
          <Grid className="brand" item sm={6}>
            <img src={logo} alt="Brand" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Banner;
